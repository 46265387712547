import React, { useEffect, useState } from 'react';
import Book from '../Book';
import { useSelector, useDispatch } from 'react-redux';
import { setCoverColour, setCoverType } from './../../store/actions/object-action/object-action';
import { bookType } from './../../utils/constants';
import { setCurrentStep } from './../../store/actions/step-action/step-actions';
import { setOrderingExitModal } from './../../store/actions/modal-action/modal-action';

const CoverStep = () => {
  const dispatch = useDispatch();

  const coverColourInit = useSelector((state) => state.objectReducer.cover_colour);
  const coverTypeInit = useSelector((state) => state.objectReducer.cover_type);
  const genderInit = useSelector((state) => state.objectReducer.gender);
  const nameBookInit = useSelector((state) => state.objectReducer.name);
  const characterInit = useSelector((state) => state.objectReducer.character);
  const wishInit = useSelector((state) => state.objectReducer.wish);


  const [downloadBook, setDownloadBook] = useState(false);
  const [screenSize, setScreenSize] = useState(0);

  const onPrevClick = () => {
    hangleSetCurrentStep(3)
  }

  function handleResize() {
    setScreenSize(window.innerWidth);
  }
  useEffect(()=>{
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  },[]);

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, [])

  useEffect(() => {
    setDownloadBook(false);
  }, [coverColourInit, dispatch])

  // const handleSetCoverColour = (color) => {
  //   dispatch(setCoverColour(color))
  // }

  const handleSetCoverType = () => {
    if (coverTypeInit === bookType.hard) dispatch(setCoverType(bookType.soft));
    
    if (coverTypeInit === bookType.soft) dispatch(setCoverType(bookType.hard));
  }

  const openOrderingExitModal = () => {
    dispatch(setOrderingExitModal(true));
  }

  const hangleSetCurrentStep = (step) => {
    dispatch(setCurrentStep(step));
  }

  
  const isSoftCover = () => {
    if (coverTypeInit === bookType.hard) return false;
    
    if (coverTypeInit === bookType.soft) return true;
  }

  const activeBigColourDot = (colour) => (
      <div className="dot-bigger_dashed">
        <div className={`dot-bigger dot_${colour}`}/>
      </div>
  )

  const inactiveBigColourDot = (colour) => (
    <div
      className={`dot-bigger dot_${colour}`}
      onClick={() =>{
        setDownloadBook(true);
        dispatch(setCoverColour(colour))
      }}
    />

  )

  const onNextClick = () => {
    openOrderingExitModal();
    // hangleSetCurrentStep(5);
    window.ym(73927030,'reachGoal','fives-step-button');
  }
  return <>
    <h1 className="header-block-title constructor">Выберите обложку книги</h1>
    <div className="cover-is-chosen">
      <div className="cover-is-chosen__header">
        <div className="cover-is-chosen__header__colours">
          <span>Цвета:</span>
          {coverColourInit === 'yellow'
              ? activeBigColourDot('yellow')
              : inactiveBigColourDot('yellow')
          }
          {coverColourInit === 'blue'
              ? activeBigColourDot('blue')
              : inactiveBigColourDot('blue')
          }
          {coverColourInit === 'pink'
              ? activeBigColourDot('pink')
              : inactiveBigColourDot('pink')
          }
        </div>
        <div className="gender_section__info__toggler see-the-book__toggler cover">
          <span className={'gender_section__info__toggler__boy see-the-book__toggler__boy cover-is-chosen__toggler ' + (
              isSoftCover() ? 'active' : ''
          )}
          >
            <span className="cover-is-chosen__toggler__first-line">Мягкая обложка</span>
            <span className="cover-is-chosen__toggler__second-line">2490 руб.</span>
          </span>

          <span
              className={'gender_section__info__toggler__girl see-the-book__toggler__girl cover-is-chosen__toggler ' + (
                !isSoftCover() ? 'active' : ''
              )}>
            <span className="cover-is-chosen__toggler__hard-first-line">Твёрдая обложка</span>
            <span className="cover-is-chosen__toggler__hard-second-line">3490 руб.</span>
          </span>
          <input
            type="checkbox"
            className="toggle-button"
            checked={!isSoftCover()}
            onChange={() => {
                handleSetCoverType()
            }}
          />
        </div>
      </div>

      {
        downloadBook 
          ? <></>
          : <Book
              // colour={coverColourInit}
              parentClass="book-cover-step"
              genderData={{
                character: characterInit,
                cover_colour: coverColourInit,
                gender: genderInit,
                name: nameBookInit,
                wish : wishInit,
                page: "INIT",
              }}
            />
      }

    </div>

    <div className="btns-block book-mobile cover">
      {/*<div className="btn-block-wrapper">*/}

      <button className="btn-icon btn-white constructor-btn" onClick={onPrevClick}>
        <i className="white-arrow-left"/>
        Назад
      </button>
      <button
        className="btn-icon btn-green constructor-btn"
        id="fives-step-link"
        disabled={isSoftCover === null && coverColourInit === null}
        onClick={(e) => {
          // hangleSetCurrentStep(5);
          onNextClick(e)
        }}
      >
        {(screenSize >= 767) ? 'Добавить в корзину'
          : 'Готово'
        }

        <i className="white-arrow-right"/>
      </button>
      {/*</div>*/}
    </div>
  </>
}

export default CoverStep
